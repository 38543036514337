import { isFunction, isObject } from 'lodash';
import * as _ from 'lodash';

import { DeepPartial } from '../types/mixins';
import { Unique } from '../unique';

export { };

declare global {
    interface Object {
        readonly $cid: string;

        forEach<T, K extends keyof T>(this: T, callbackfn: (value: T[K], key: K, obj: T) => void, thisArg?: any): void;

        reduce<U, T, K extends keyof T>(this: T, callbackfn: (previousValue: U, currentValue: T[K], key: K, object: T) => U, initialValue: U, thisArg?: any): U;

        match<T>(this: T, attrs: DeepPartial<T> | ((item: any) => boolean)): boolean;
    }
}

function _polyfill(Object: ObjectConstructor) {

    if (!Object || Object.prototype.$cid) {
        return;
    }

    if (!Object.prototype.$cid) {
        Object.defineProperty(Object.prototype, '$cid', {
            enumerable: false,
            configurable: true,
            get: function (this: Object): string {
                return Unique.numbered(this);
            }
        })
    }

    if (!Object.prototype.forEach) {
        Object.defineProperty(Object.prototype, 'forEach', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <T, K extends keyof T>(this: T, callbackfn: (value: T[K], key: K, obj: T) => void, thisArg?: any): void {
                for (var k in this) {
                    if (this.hasOwnProperty(k)) {
                        callbackfn.call(thisArg, this[k], k, this);
                    }
                }
            }
        })
    }

    if (!Object.prototype.reduce) {
        Object.defineProperty(Object.prototype, 'reduce', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function <U, T, K extends keyof T>(this: T, callbackfn: (previousValue: U, currentValue: T[K], key: K, object: T) => U, initialValue: U, thisArg?: any): U {
                for (var k in this) {
                    if (this.hasOwnProperty(k)) {
                        initialValue = callbackfn.call(thisArg, initialValue, this[k], k, this);
                    }
                }

                return initialValue;
            }
        })
    }

    if (!Object.prototype.match) {
        Object.defineProperty(Object.prototype, 'match', {
            writable: true,
            enumerable: false,
            configurable: true,
            value: function match<T>(this: T, attrs: DeepPartial<T> | ((item: T) => boolean)): boolean {
                // no filter condition, return matched.
                if (!attrs) return true;

                if (!isObject(this)) return false;

                if (isFunction(attrs)) {
                    return attrs(this);
                }

                return _.isMatchWith(this, <object>attrs, (value: any, other: any): boolean | undefined => {
                    if (_.isArray(value) || !_.isArray(other)) return;

                    for (other of other) {
                        if (_.isMatch(value, other)) {
                            return true;
                        }
                    }
                });
            }
        })
    }

}

_polyfill(Object);

export namespace _Object {
    export function polyfill(wnd: Window) {
        wnd && _polyfill(wnd['Object']);
    }
}